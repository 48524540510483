// ImageSection.js
import React from 'react';
import { Form, Input, Upload, Button, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API_PATH } from '../../../config';


const ImageSection = ({ field, form, completeName }) => {
  const bloqued = form.getFieldValue([...completeName, 'bloqued']) || [];
  return (
    <>
      {/* Campo oculto para 'bloqued' */}
      <Form.Item name={[field.name, 'bloqued']} hidden>
        <Input />
      </Form.Item>

      {/* Campo para la Imagen */}
      <Form.Item
        label="Imagen"
        name={[field.name, 'image']}
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
        rules={[{ required: true, message: 'Seleccione una imagen' }]}
      >
        <Upload
          name="image"
          listType="picture"
          // beforeUpload={() => false} // Evita la carga automática
          // customRequest={handleUpload} // Maneja la carga personalizada
          action={`${API_PATH}/upload/image/documentos`}
          disabled={bloqued.includes('image')}
          maxCount={1}
        >
          <Button
            icon={<UploadOutlined />}
            disabled={bloqued.includes('image')}
          >
            Seleccionar Imagen
          </Button>
        </Upload>
      </Form.Item>

      {/* Campo para el Pie de Imagen */}
      <Form.Item
        label="Pie de Imagen"
        name={[field.name, 'caption']}
        rules={[{ required: true, message: 'Ingrese el pie de imagen' }]}
      >
        <Input
          placeholder="Pie de Imagen"
          disabled={bloqued.includes('caption')}
        />
      </Form.Item>
    </>
  );
};

export default ImageSection;
