import React from 'react';
import { Form, Input, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API_PATH } from 'config';

const FileForm = (props) => {
  const { disabledFields = [], ...formProps } = props;

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 20; // Tamaño menor a 5 MB
    if (!isLt5M) {
      message.error('El archivo debe ser menor que 5MB');
    }
    return isLt5M;
  };

  const isDisabled = (fieldName) => {
    return disabledFields.includes(fieldName);
  };

  return (
    <Form {...formProps} layout="vertical">
      <Form.Item name="idStorage" style={{ display: 'none' }}>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="title"
        label="Título"
        rules={[{ required: true, message: 'Ingresa el título del archivo' }]}
      >
        <Input
          placeholder="Ingresa el título del archivo"
          disabled={isDisabled('title')}
        />
      </Form.Item>
      <Form.Item
        name="description"
        label="Descripción"
        rules={[{ required: true, message: 'Ingresa una descripción' }]}
      >
        <Input.TextArea
          rows={4}
          placeholder="Ingresa una descripción del archivo"
          disabled={isDisabled('description')}
        />
      </Form.Item>
      <Form.Item
        name="files"
        label="Archivo"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: 'Sube el archivo' }]}
        extra="Subir un archivo"
      >
        <Upload
          action={`${API_PATH}/upload/file/presupuesto`}
          listType="picture"
          beforeUpload={beforeUpload}
          maxCount={1}
          disabled={isDisabled('files')}
        >
          <Button icon={<UploadOutlined />} disabled={isDisabled('files')}>
            Seleccionar archivo
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={isDisabled('submit')}
        >
          Subir Archivo
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FileForm;
