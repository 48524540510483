// ChangelogItem.jsx
import React from 'react';
import { Typography, List } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

function ChangelogItem({ version }) {
  return (
    <div className="mb-6">
      {/* Encabezado de la Versión */}
      <div className="flex items-center justify-between border-b pb-2 mb-4">
        <Title level={4} className="!mb-0">
          Versión {version.version}
        </Title>
        <Text type="secondary">{version.date}</Text>
      </div>

      {/* Lista de Cambios */}
      <List
        size="small"
        dataSource={version.changes}
        renderItem={(change) => (
          <List.Item className="py-1">
            <CheckCircleOutlined className="text-blue-500 mt-1 mr-2 flex-shrink-0" />
            <Text>{change.description}</Text>
          </List.Item>
        )}
      />
    </div>
  );
}

export default ChangelogItem;
