import { Button, Checkbox, Form, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { deleteToApi, postToApi, putToApi } from 'api/axios';
import { STATUS_ERROR, STATUS_LOADING, useCallGetApi } from 'api/useCallApi';
import { mesesData } from 'assets/data/meses';
import ErrorComponent from 'components/ErrorComponent';
import {
  showErrorNotification,
  showNotification,
} from 'components/views/Notifications';
import React, { useEffect } from 'react';

const BASE_DIRECTION = '/actividad';

export const deleteActivity = async (id, isFinish) => {
  try {
    const response = await deleteToApi(`${BASE_DIRECTION}/delete/${id}`);
    showNotification(
      response,
      'El servidor no ha devuelto un mensaje de confirmación. Por favor verifique si fué agregado.'
    );
    isFinish();
    return {
      resetFields: true,
    };
  } catch (error) {
    showErrorNotification(error, 'Error  del servidor al eliminar');
    return {
      resetFields: false,
    };
  }
};
export const addActivity = async (values, isFinish) => {
  try {
    const response = await postToApi(`${BASE_DIRECTION}/add`, values);
    showNotification(
      response,
      'El servidor no ha devuelto un mensaje de confirmación. Por favor verifique si fué agregado.'
    );
    isFinish();
    return {
      resetFields: true,
    };
  } catch (error) {
    showErrorNotification(error, 'Error  del servidor al añadir');
    return {
      resetFields: false,
    };
  }
};

export const editActivity = async (data, id, isFinish) => {
  try {
    const response = await putToApi(`${BASE_DIRECTION}/update/${id}`, data);
    showNotification(
      response,
      'El servidor no ha devuelto un mensaje de confirmación. Por favor verifique si fué editado.'
    );
    isFinish();
    return {
      resetFields: true,
    };
  } catch (error) {
    showErrorNotification(error, 'Error  del servidor al editar');

    return {
      resetFields: false,
    };
  }
};

const ActivityMaintanceForm = (props) => {
  const { submitForm, buttonLabel, formProps } = props;
  const [form] = useForm();


  const [categorias, callCategorias, statusCategorias, errorInfo] =
    useCallGetApi('/categoria/all', {
      defaultValue: [],
    });
  useEffect(() => {
    callCategorias();
  }, [callCategorias]);

  useEffect(() => {
    form.resetFields();
  }, [formProps, form]);

  const finishForm = async () => {
    const values = form.getFieldsValue();
    const response = await submitForm(values, formProps);
    if (response.resetFields) {
      form.resetFields();
    }
  };

  if (statusCategorias === STATUS_ERROR) {
    return <ErrorComponent message={errorInfo.message} />;
  }
  return (
    <Form {...formProps} form={form} onFinish={finishForm} layout="vertical">
      <Form.Item
        label="Descripción de la Actividad"
        name="actividad"
        rules={[
          {
            required: true,
            message: 'La descripción es obligatoria',
          },
        ]}
      >
        <Input placeholder="Ingresar la descripción de la actividad" />
      </Form.Item>
      <Form.Item
        label="Resumen Actividad"
        name="resumen"
        help="Este item debe ser corto y será el que estará en el informe final"
        rules={[
          {
            required: true,
            message: 'El resumen de la actividad es obligatoria',
          },
          {
            max: 120,
            message: 'Máximo 120 caracteres.',
          },
        ]}
      >
        <Input placeholder="Ingresar el resumen de la actividad" />
      </Form.Item>
      <Form.Item
        label="Categoría de la actividad"
        name="id_categoria"
        help="Este item debe ser corto y será el que estará en el informe final"
        rules={[
          {
            required: true,
            message: 'El resumen de la actividad es obligatoria',
          },
        ]}
      >
        <Select
          placeholder="Categorías"
          showSearch
          optionFilterProp="children"
          popupClassName="uppercase"
          loading={statusCategorias === STATUS_LOADING}
          fieldNames={{
            label: 'nombre',
            value: 'id',
          }}
          options={categorias}
          filterOption={(input, option) => {
            return (option?.name ?? '')
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
          filterSort={(optionA, optionB) =>
            (optionA?.name ?? '')
              .toLowerCase()
              .localeCompare((optionB?.name ?? '').toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        label="Seleccione las fechas que le corresponden"
        name={'Fechas'}
        rules={[
          {
            required: true,
            message: 'Seleccione al menos una fecha',
            type: 'array',
          },
        ]}
      >
        <Select
          mode="multiple"
          options={mesesData}
          placeholder="Seleccione los meses que corresponden a esta actividad"
        />
      </Form.Item>
      <Form.Item>
        <Button block type="primary" htmlType="submit">
          {buttonLabel || 'Enviar'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ActivityMaintanceForm;
