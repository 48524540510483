import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import ClienteDisplayForm from 'components/forms/ClienteDisplayForm';
import useAuth from 'hooks/useAuth';
import { useCliente } from 'providers/Clientes/ClienteProvider';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import log from 'eslint-plugin-react/lib/util/log';

const ClienteInicioPage = ({ id }) => {
  const { call, data, isLoading, hasCalled, update } = useCliente();
  const [disabledSave, setDisabledSave] = useState(true);
  useEffect(() => {
    call(id);
  }, [call, id]);

  const [clientForm] = useForm();

  useEffect(() => {
    if (data) {
      clientForm.setFieldsValue({
        ...data,
        proxCert: data.proxCert ? dayjs(data.proxCert) : null,
      });
    }
  }, [data, clientForm]);

  const save = async () => {
    try {
      const values = await clientForm.validateFields();
      const result = await update(id, values);
      notification.success({
        message: 'Cliente actualizado',
        description: 'El cliente ha sido actualizado exitosamente',
      });
      setDisabledSave(true);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'No se pudo guardar el cliente',
      });
    }
  };
  const { user } = useAuth();
  return (
    <Card
      loading={isLoading}
      title="Datos del cliente"
      extra={
        user.is_ejecutivo && (
          <Button
            icon={<SaveOutlined />}
            type="primary"
            onClick={save}
            disabled={disabledSave}
          >
            Guardar
          </Button>
        )
      }
    >
      {!isLoading && data ? (
        <ClienteDisplayForm
          disabled={!user.is_ejecutivo}
          form={clientForm}
          layout="vertical"
          initialValues={{
            ...data,
            proxCert: data.proxCert ? dayjs(data.proxCert) : null,
          }}
          onFieldsChange={() => setDisabledSave(false)}
        />
      ) : null}
    </Card>
  );
};

export default ClienteInicioPage;
