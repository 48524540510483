// src/features/Especialistas/pages/EdificiosPage.jsx
import React, { useState } from 'react';
import { Card, Button, Divider } from 'antd';
import Title from 'antd/es/typography/Title';
import EdificiosTable from './components/EdificiosTable';
import EdificiosTour from './components/EdificiosTour';
import { useEdificios } from './hooks/useEdificios';

const EdificiosPage = () => {
  const { columns, filteredData, isLoading, columnRefs } = useEdificios();
  const [isTourOpen, setIsTourOpen] = useState(false);

  return (
    <div className="space-y-2">
      <Card className="welcome text-center md:text-left">
        <Title level={3} className="!text-white">
          EDIFICIOS
        </Title>
        <Button
          type="primary"
          onClick={() => setIsTourOpen(true)}
          style={{ marginTop: '16px' }}
        >
          Iniciar Tour (Beta)
        </Button>
      </Card>
      <Divider />
      <EdificiosTable
        columns={columns}
        data={filteredData}
        loading={isLoading}
      />

      <EdificiosTour
        open={isTourOpen}
        onClose={() => setIsTourOpen(false)}
        columnRefs={columnRefs}
      />
    </div>
  );
};

export default EdificiosPage;
