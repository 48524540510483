// src/features/Especialistas/hooks/useEdificios.js
import { useState, useRef, useEffect } from 'react';
import moment from 'moment';

import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useGetEdificios } from './useGetEdificios';
import HighlightText from '../../../../common/components/HighlightText';

export const useEdificios = () => {
  const { clients, isLoading, callClients } = useGetEdificios();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  // Crear refs para las columnas
  const idRef = useRef(null);
  const nameRef = useRef(null);
  const nAscensoresRef = useRef(null);
  const addressRef = useRef(null);
  const communeRef = useRef(null);
  const createdAtRef = useRef(null);
  const updatedAtRef = useRef(null);
  const actionsRef = useRef(null);
  const viewRef = useRef(null);

  useEffect(() => {
    callClients();
  }, [callClients]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Resetear
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <HighlightText text={text} highlight={searchText} />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: <div ref={idRef}>ID</div>,
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: <div ref={nameRef}>Nombre</div>,
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: <div ref={nAscensoresRef}>Ascensores</div>,
      dataIndex: 'nAscensores',
      key: 'nAscensores',
      sorter: (a, b) => a.nAscensores - b.nAscensores,
    },
    {
      title: <div ref={addressRef}>Dirección</div>,
      dataIndex: 'address',
      key: 'address',
      sorter: (a, b) => a.address.localeCompare(b.address),
    },
    {
      title: <div ref={communeRef}>Comuna</div>,
      dataIndex: 'commune',
      key: 'commune',
    },
    {
      title: <div ref={createdAtRef}>Creado En</div>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: <div ref={updatedAtRef}>Actualizado En</div>,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
    },
    {
      title: (
        <div key="actions" ref={actionsRef}>
          Acciones
        </div>
      ),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, record, index) => (
        <Link to={`/specialist/edificios/${record.id}`}>
          <Button
            type="link"
            icon={<EyeOutlined />}
            ref={index === 0 ? viewRef : null}
          >
            Ver
          </Button>
        </Link>
      ),
    },
  ];

  const filteredData =
    searchedColumn === 'name' && searchText
      ? clients?.data?.filter((item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase())
        )
      : clients.data || [];

  return {
    columns,
    filteredData,
    isLoading,
    columnRefs: {
      idRef,
      nameRef,
      nAscensoresRef,
      addressRef,
      communeRef,
      createdAtRef,
      updatedAtRef,
      actionsRef,
      viewRef,
    },
  };
};
