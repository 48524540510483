import { Button, Form, Select } from 'antd';
import ClienteCompleteSelect from 'components/selects/ClienteCompleteSelect';
import useRequest from 'hooks/useRequest';
import React, { useCallback, useEffect, useState } from 'react';

const PresupuestoForm = (props) => {
  const { loading, ...formProps } = props;
  const { request, isLoading: isLoadingReq, errors } = useRequest();
  const [presupuestos, setPresupuestos] = useState([]);
  const getPresupuestos = useCallback(async () => {
    const result = await request(`/presupuestos/only-composer`, 'GET');
    setPresupuestos(result);
    return result;
  }, [request]);
  useEffect(() => {
    getPresupuestos();
  }, [getPresupuestos]);

  // const {}
  return (
    <Form {...formProps} layout="vertical">
      <Form.Item name="cliente" label="Cliente" rules={[{ required: true }]}>
        <ClienteCompleteSelect />
      </Form.Item>
      <Form.Item name="otherBudgetId" label="Copiar de otro presupuesto">
        <Select placeholder="Seleccione un presupuesto">
          {presupuestos?.map((presupuesto) => (
            <Select.Option key={presupuesto.id} value={presupuesto.id}>
              {presupuesto.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button loading={loading} htmlType="submit" block type="primary">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PresupuestoForm;
