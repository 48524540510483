import { Button, Form, Input, Select, Checkbox } from 'antd';
import { STATUS_ERROR, STATUS_LOADING, useCallGetApi } from 'api/useCallApi';
import ErrorComponent from 'components/ErrorComponent';
import React, { useEffect, useState } from 'react';

const { Option } = Select;

const ActividadMantencionForm = (props) => {
  const { isLoadingAdd, ...formProps } = props;
  const [categorias, callCategorias, statusCategorias, errorInfo] =
    useCallGetApi('/categoria/all', {
      defaultValue: [],
    });
  const [tipoIntervalo, setTipoIntervalo] = useState('meses');
  useEffect(() => {
    callCategorias();
  }, [callCategorias]);

  useEffect(() => {
    if ((formProps?.form, formProps?.initialValues)) {
      formProps.form.resetFields();
    }
  }, [formProps?.form, formProps?.initialValues]);

  if (statusCategorias === STATUS_ERROR) {
    return <ErrorComponent message={errorInfo.message} />;
  }

  return (
    <Form {...formProps}>
      <Form.Item name="type" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="id_item_coleccion" hidden>
        <Input />
      </Form.Item>
      <Form.Item name={'id_cliente'} hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label="Título de la Actividad"
        name="title"
        rules={[
          {
            required: true,
            message: 'El título es obligatorio',
          },
        ]}
      >
        <Input placeholder="Ingresar el título" />
      </Form.Item>

      <Form.Item
        label="Actividad"
        name="actividad"
        help="Este ítem es el que se mostrará en el informe final."
        rules={[
          {
            required: true,
            message: 'La actividad es obligatoria',
          },
        ]}
      >
        <Input placeholder="Ingresar la actividad" />
      </Form.Item>

      <Form.Item
        label="Categoría de la actividad"
        name="id_categoria"
        rules={[
          {
            required: true,
            message: 'La categoría es obligatoria',
          },
        ]}
      >
        <Select
          placeholder="Categorías"
          showSearch
          optionFilterProp="children"
          popupClassName="uppercase"
          loading={statusCategorias === STATUS_LOADING}
          fieldNames={{
            label: 'nombre',
            value: 'id',
          }}
          options={categorias}
          filterOption={(input, option) => {
            return (option?.nombre ?? '')
              .toLowerCase()
              .includes(input.toLowerCase());
          }}
          filterSort={(optionA, optionB) =>
            (optionA?.nombre ?? '')
              .toLowerCase()
              .localeCompare((optionB?.nombre ?? '').toLowerCase())
          }
        />
      </Form.Item>

      {/*<Form.Item label="Obligatorio" name="obligatorio" valuePropName="checked">*/}
      {/*  <Checkbox>¿Es obligatorio?</Checkbox>*/}
      {/*</Form.Item>*/}

      <Form.Item
        label="Tipo de Intervalo"
        name="tipo_intervalo"
        rules={[
          {
            required: true,
            message: 'El tipo de intervalo es obligatorio',
          },
        ]}
      >
        <Select
          disabled
          // defaultValue={['meses']}
          // placeholder="Seleccione el tipo de intervalo"
          // onChange={handleTipoIntervaloChange}
        >
          {/* <Option value="dias">Días</Option>
          <Option value="semanas">Semanas</Option> */}
          <Option value="meses">Meses</Option>
          {/* <Option value="años">Años</Option> */}
        </Select>
      </Form.Item>
      <Form.Item name="isTemplate" hidden>
        <Input />
      </Form.Item>

      {/* {tipoIntervalo && (
        <Form.Item
          label="Intervalo de Repetición"
          name="intervalo_repeticion"
          rules={[
            {
              required: true,
              message: 'El intervalo de repetición es obligatorio',
            },
          ]}
          help={getIntervaloRepeticionHelpText()}
        >
          <InputNumber min={1} placeholder="Intervalo de repetición" />
        </Form.Item>
      )} */}

      {/* {tipoIntervalo === 'dias' && (
        <Form.Item
          label="Días de la Semana"
          name="dias_semana"
          rules={[
            {
              required: true,
              message: 'Seleccione al menos un día de la semana',
            },
          ]}
        >
          <Select mode="multiple" placeholder="Seleccione días de la semana">
            <Option value={0}>Domingo</Option>
            <Option value={1}>Lunes</Option>
            <Option value={2}>Martes</Option>
            <Option value={3}>Miércoles</Option>
            <Option value={4}>Jueves</Option>
            <Option value={5}>Viernes</Option>
            <Option value={6}>Sábado</Option>
          </Select>
        </Form.Item>
      )} */}

      {tipoIntervalo === 'meses' && (
        <Form.Item
          label="Meses del Año"
          name="Fechas"
          rules={[
            {
              required: true,
              message: 'Seleccione al menos un mes del año',
            },
          ]}
        >
          <Select mode="multiple" placeholder="Seleccione meses">
            <Option value={1}>Enero</Option>
            <Option value={2}>Febrero</Option>
            <Option value={3}>Marzo</Option>
            <Option value={4}>Abril</Option>
            <Option value={5}>Mayo</Option>
            <Option value={6}>Junio</Option>
            <Option value={7}>Julio</Option>
            <Option value={8}>Agosto</Option>
            <Option value={9}>Septiembre</Option>
            <Option value={10}>Octubre</Option>
            <Option value={11}>Noviembre</Option>
            <Option value={12}>Diciembre</Option>
          </Select>
        </Form.Item>
      )}

      <Form.Item>
        <Button block type="primary" htmlType="submit" loading={isLoadingAdd}>
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ActividadMantencionForm;
