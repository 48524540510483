/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { Suspense, useMemo, useState, useRef } from 'react';
import { Button, Modal, Space, Table, Tag, Input, Icon, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes, { bool, func, shape } from 'prop-types';
import BudgetBadge from '../BudgetBadge/BudgetBadge';
import BudgetDropdownButton from '../BudgetDropdownButton/BudgetDropdownButton';

const { Text } = Typography;

function BudgetTable(props) {
  const {
    budgets,
    refreshBudgets,
    modalOpen,
    handleOpenModal,
    ModalComponent,
    modalOptions,
    modalProps,
    modalAtributes,
  } = props;

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const refreshData = () => {
    refreshBudgets(true);
  };
  const editBudget = (record) => {
    modalOptions.setModalToEditForm(record);
    handleOpenModal(true);
  };
  const openOrderForm = (record) => {
    modalOptions.setModalToODTForm(record);
    handleOpenModal(true);
  };
  const openStateForm = (record) => {
    modalOptions.setModalToODTSatus(record);
    handleOpenModal(true);
  };
  const openDowloadOrder = (record) => {
    modalOptions.setModalToODTDowload(record.idBudget);
    handleOpenModal(true);
  };
  const closeModal = () => {
    handleOpenModal(false);
  };
  const { clientNames, status } = useMemo(() => {
    // Inicializa el objeto con arrays vacíos para los nombres y estados.
    const initial = { clientNames: [], status: [] };

    const result = budgets.reduce((acc, budget) => {
      // Verifica si el nombre ya existe en el acumulador.
      if (!acc.clientNames.some((item) => item.value === budget.name)) {
        acc.clientNames.push({ value: budget.name, text: budget.name });
      }

      // Verifica si el estado ya existe en el acumulador.
      if (!acc.status.some((item) => item.value === budget.status)) {
        acc.status.push({
          text: budget.statusDescription,
          value: budget.status,
        });
      }

      return acc;
    }, initial);

    // Devuelve los objetos formateados.
    return {
      clientNames: result.clientNames,
      status: result.status,
    };
  }, [budgets]);

  // Funciones para manejar la búsqueda
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  // Función para obtener las propiedades de búsqueda de la columna
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Text strong>{text}</Text>
      ) : (
        text
      ),
  });

  return (
    <>
      <Table
        dataSource={budgets}
        columns={[
          {
            title: 'Estado',
            key: 'status',
            width: '10%',
            filters: status,
            onFilter: (value, record) => record.status.includes(value),
            render: (record) => (
              <Tag color={record.statusColor}>{record.statusDescription}</Tag>
            ),
          },
          {
            title: 'ID',
            dataIndex: 'idBudget',
            key: 'idBudget',
            width: '6%',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.idBudget - b.idBudget,
            showSorterTooltip: false,
          },
          {
            title: 'Cliente',
            dataIndex: 'name',
            filters: clientNames,
            onFilter: (value, record) => record.name.includes(value),
            key: 'name',
          },
          {
            title: 'Presupuesto',
            dataIndex: 'reason',
            key: 'reason',
            ...getColumnSearchProps('reason'), // Añadimos las propiedades de búsqueda aquí
          },
          {
            title: 'Dirección',
            dataIndex: 'address',
            key: 'address',
          },
          {
            title: 'Comuna',
            dataIndex: 'commune',
            key: 'commune',
          },
          {
            title: 'Correo',
            dataIndex: 'email',
            key: 'email',
          },
          {
            title: 'Acciones',
            key: 'action',
            render: (record) => (
              <Space>
                {record.status === 'odt_no_asignada' ? (
                  <Button type="primary" onClick={() => openOrderForm(record)}>
                    ODT
                  </Button>
                ) : null}
                {record.status === 'odt_en_proceso' ? (
                  <Button type="primary" onClick={() => openStateForm(record)}>
                    ODT
                  </Button>
                ) : null}
                {record.status === 'odt_asignada' ? (
                  <Button type="primary" onClick={() => openStateForm(record)}>
                    ODT
                  </Button>
                ) : null}
                {record.status === 'odt_finalizada' ? (
                  <Button
                    type="primary"
                    onClick={() => openDowloadOrder(record)}
                  >
                    ODT
                  </Button>
                ) : null}
                <BudgetDropdownButton
                  budget={record}
                  refreshData={refreshData}
                  editBudget={editBudget}
                />
              </Space>
            ),
          },
        ]}
        rowKey="idBudget"
        scroll={{ x: 1500 }}
      />
      <Modal
        open={modalOpen}
        onCancel={closeModal}
        footer={modalAtributes.footer}
        title={modalAtributes.title}
        width={1000}
      >
        <Suspense fallback={<div>Cargando...</div>}>
          {ModalComponent && <ModalComponent {...modalProps} />}
        </Suspense>
      </Modal>
    </>
  );
}

const budgetProps = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  commune: PropTypes.string.isRequired,
  email: PropTypes.string,
  reason: PropTypes.string.isRequired,
  guarantee: PropTypes.string.isRequired,
  include: PropTypes.string.isRequired,
  conditions: PropTypes.string.isRequired,
  createdOn: PropTypes.string,
  description: PropTypes.string.isRequired,
  finishTime: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  discount: PropTypes.number,
};

BudgetTable.propTypes = {
  budgets: PropTypes.arrayOf(PropTypes.shape(budgetProps)),
  refreshBudgets: PropTypes.func.isRequired,
  modalOpen: bool.isRequired,
  handleOpenModal: func.isRequired,
  ModalComponent: shape({}),
  modalOptions: shape({}).isRequired,
  modalProps: shape({}),
  modalAtributes: shape({}).isRequired,
};

BudgetTable.defaultProps = {
  budgets: [],
  ModalComponent: {},
  modalProps: {},
};
export default BudgetTable;
