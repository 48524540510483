import { PlusOutlined } from '@ant-design/icons';
import CategoryListContainer from 'Containers/CategoryListContainer';
import { Button, Card, Drawer, Modal } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { STATUS_ERROR, STATUS_LOADING, useCallGetApi } from 'api/useCallApi';
import ErrorComponent from 'components/ErrorComponent';
import ActivityMaintanceForm, {
  addActivity,
  deleteActivity,
  editActivity,
} from 'components/forms/ActivityMaintanceForm';
import EliminarForm from 'components/forms/EliminarForm';
import MaintanceActivitiesList from 'components/lists/MaintanceActivitiesList';
import React, { useEffect, useState } from 'react';

const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};

const Activities = () => {
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const [drawerProps, setDrawerProps] = useState(defaultModalProps);
  const [actividades, callActividades, statusActividades, errorInfo] =
    useCallGetApi('/actividad/all', {
      defaultValue: [],
    });

  useEffect(() => {
    callActividades();
  }, [callActividades]);

  const successFinish = () => {
    callActividades();
    setModalProps(defaultModalProps);
  };

  const openDelete = async (data) => {
    setModalProps({
      open: true,
      children: (
        <EliminarForm
          title="Eliminar Actividad"
          id={data.id}
          deleteRegister={(id) => deleteActivity(id, successFinish)}
        />
      ),
    });
  };

  const openEdit = async (data) => {
    const Fechas = data.Fechas.map((fecha) => {
      return fecha.mes;
    });
    setModalProps({
      open: true,
      children: (
        <ActivityMaintanceForm
          buttonLabel="Editar"
          submitForm={(values) => editActivity(values, data.id, successFinish)}
          formProps={{
            initialValues: { ...data, Fechas },
          }}
        />
      ),
      title: 'Editar actividad',
    });
  };

  const openAdd = () => {
    setModalProps({
      open: true,
      children: (
        <ActivityMaintanceForm
          buttonLabel="Agregar"
          isTemplate
          submitForm={(values) => addActivity(values, successFinish)}
        />
      ),
      title: 'Agregar Actividad',
    });
  };

  const reload = () => {
    callActividades();
  };
  const openCategory = () => {
    setDrawerProps({
      open: true,
      children: <CategoryListContainer addButton={true} reload={reload} />,
      title: 'Categorías de mantención',
    });
  };
  if (statusActividades === STATUS_ERROR) {
    return <ErrorComponent message={errorInfo.message} />;
  }

  return (
    <Content>
      <Card>
        <div className="flex flex-col md:flex-row justify-between mb-3">
          <h3 className="uppercase mx-auto mb-2 md:mx-0">
            Actividades{' '}
            <span className="hidden md:inline-block">de mantención</span>
          </h3>
          <div className="flex flex-row justify-between">
            <Button
              className="mr-4 w-full"
              type="default"
              icon={<PlusOutlined />}
              onClick={openCategory}
            >
              Categorias
            </Button>
            <Button
              type="primary"
              className="w-full"
              icon={<PlusOutlined />}
              onClick={openAdd}
            >
              Actividad
            </Button>
          </div>
        </div>
      </Card>
      <MaintanceActivitiesList
        dataSource={actividades}
        isLoading={statusActividades === STATUS_LOADING}
        openDelete={openDelete}
        openEdit={openEdit}
      />
      <Modal
        {...modalProps}
        footer={false}
        onCancel={() => setModalProps(defaultModalProps)}
      />
      <Drawer
        {...drawerProps}
        footer={false}
        onClose={() => setDrawerProps(defaultModalProps)}
      ></Drawer>
    </Content>
  );
};

export default Activities;
