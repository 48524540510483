import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, notification, Select, Table, Tag } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';

import useRequest from 'hooks/useRequest';
import { useClientes } from 'providers/ClientesProvider';
import { useColeccion } from '../../providers/ColeccionProvider';

// Función auxiliar para obtener los nombres de los meses
const getMonthNames = (months) => {
  const monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  return months
    .sort((a, b) => a - b)
    .map((month) => monthNames[month - 1])
    .join(', ');
};

const ImportActividadesForm = ({ id, onFinish }) => {
  // Hooks personalizados
  const { activeData, call, isLoading } = useClientes();
  const { getAllItems } = useColeccion();
  const { id: idColeccion, itemId } = useParams();
  const { request, isLoading: isLoadingRequest, errors, data } = useRequest();

  // Estados
  const [itemsColeccion, setItemsColeccion] = useState([]);
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  // Efecto para obtener los ítems de la colección al montar el componente
  useEffect(() => {
    const fetchItems = async () => {
      const result = await getAllItems(idColeccion);
      console.log(result);
      setItemsColeccion(result.items);
      // setColeccion(result.coleccion);
    };
    fetchItems();
  }, [getAllItems, idColeccion]);

  // Efecto para llamar a la función `call` al montar el componente
  useEffect(() => {
    call();
  }, [call]);

  // Función para obtener los ítems de una colección específica
  const callItems = async (id_coleccion) => {
    const result = await request(`/colecciones/items/${id_coleccion}`, 'GET');
    setItems(result?.Actividades);
    return result;
  };

  // Transformación de datos para la tabla
  const transformedData = items?.map((activity) => ({
    ...activity,
    meses: getMonthNames(activity?.Fechas?.map((fecha) => fecha.mes)),
  }));

  // Obtención de categorías únicas para filtros
  const uniqueCategories = [
    ...new Set(items?.map((activity) => activity.Categoria.nombre)),
  ];

  // Manejo de cambios en la tabla (filtros y ordenamiento)
  const handleTableChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  // Función para manejar la importación de ítems seleccionados
  const submitImport = async () => {
    try {
      setIsLoadingAdd(true);
      if (selectedRowKeys.length === 0) {
        notification.error({
          message: 'Seleccione al menos un ítem',
        });
        setIsLoadingAdd(false); // Asegurarse de detener la carga en caso de error
        return;
      }

      const response = await request('/actividad/import', 'POST', {
        items: selectedRowKeys,
        itemId
      });

      onFinish(response, itemId);
      setIsLoadingAdd(false);
      return response;
    } catch (error) {
      setIsLoadingAdd(false);
      notification.error({
        message: 'Error al importar los ítems',
        description: error.message,
      });
    }
  };

  return (
    <div className="space-y-2">
      {/* Selección de Colección */}
      <div>
        <Paragraph>Seleccione una colección:</Paragraph>
        <Select
          loading={isLoading}
          placeholder="Seleccione una colección"
          onChange={(value) => callItems(value)}
          className="w-full"
        >
          {itemsColeccion?.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.nombre}
            </Select.Option>
          ))}
        </Select>
      </div>

      {/* Selección de Ítems para Importar */}
      <div>
        <Paragraph>Seleccione los ítems a importar:</Paragraph>
        <Table
          dataSource={transformedData}
          pagination={false}
          rowKey={'id'}
          size="small"
          onChange={handleTableChange}
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys) => {
              setSelectedRowKeys(selectedRowKeys);
            },
          }}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              sorter: (a, b) => a.id - b.id,
              sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
              defaultSortOrder: 'descend',
            },
            {
              title: 'Título',
              dataIndex: 'title',
              key: 'title',
            },
            {
              title: 'Categoría',
              dataIndex: 'Categoria',
              key: 'Categoria',
              filters: uniqueCategories.map((category) => ({
                text: category,
                value: category,
              })),
              filteredValue: filteredInfo.Categoria || null,
              onFilter: (value, record) =>
                record.Categoria.nombre.includes(value),
              render: (categoria) => (
                <div className="text-center">
                  <Tag color="blue">{categoria.nombre}</Tag>
                </div>
              ),
            },
            {
              title: 'Descripción',
              dataIndex: 'actividad',
              key: 'actividad',
            },
            {
              title: 'Meses',
              dataIndex: 'meses',
              key: 'meses',
            },
            {
              title: 'Obligatorio',
              dataIndex: 'obligatorio',
              key: 'obligatorio',
              render: (obligatorio) => (
                <div className="text-center">
                  <Tag color={obligatorio ? 'green' : 'yellow'}>
                    {obligatorio ? 'Sí' : 'No'}
                  </Tag>
                </div>
              ),
              width: 100,
            },
          ]}
        />
      </div>

      {/* Botón de Importación */}
      <div>
        <Button
          block
          type="primary"
          onClick={submitImport}
          loading={isLoadingAdd}
        >
          Importar
        </Button>
      </div>
    </div>
  );
};

export default ImportActividadesForm;
