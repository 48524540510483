import { useGetToApi } from 'hooks/useApi/useGetAxios';

export const useGetEdificios = () => {
  const [clients, isLoading, callClients] = useGetToApi('/client/all', {
    config: {
      params: { whitProblems: true },
    },
    initialValues: {
      data: [],
    },
  });

  return { clients, isLoading, callClients };
};
