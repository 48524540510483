import React from 'react';
import { Button, Card, Image, Space } from 'antd';
import LiftLogicLogo from '../../../assets/app_config/lift_logic_logo.png';
import { APP_VERSION } from 'config';
import Changelog from '../../../features/Changelog/Changelog';

function Footer() {
  return (
    <Card className="mt-2">
      <div className=" flex flex-row justify-center align-bottom items-center">
        <Image src={LiftLogicLogo} width={100} />
        <Space direction="vertical">
          <span>Desarrollado por STIL</span>
          <span>david.silva@liftlogic.cl</span>
          <span>Versión: {APP_VERSION}</span>
          <Changelog />
        </Space>
      </div>
    </Card>
  );
}

export default Footer;
