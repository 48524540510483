// useFilteredChangelog.js
import { useMemo } from 'react';
import changelogData from '../changelogData.json';

const useFilteredChangelog = (user) => {
  const filteredVersions = useMemo(() => {
    if (!user) return [];

    const determineFilter = () => {
      if (user.is_ejecutivo) {
        return 'all';
      } else if (user.is_administrador) {
        return 'administrador';
      } else if (user.is_especialista) {
        return 'especialista';
      }
      return null;
    };

    const filterType = determineFilter();

    return changelogData?.version
      .map((version) => {
        if (filterType === 'all') {
          return version;
        }

        const filteredChanges = version.changes.filter(
          (change) =>
            change.userType === filterType || change.userType === 'all'
        );

        if (filteredChanges.length > 0) {
          return { ...version, changes: filteredChanges };
        }

        return null;
      })
      .filter((version) => version !== null);
  }, [user]);

  return filteredVersions;
};

export default useFilteredChangelog;
