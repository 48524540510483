// ProductsTable.js
import React, { useEffect } from 'react';
import { Table, Input, InputNumber, Form, Button, Typography } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { formatMoneyNumber } from '../../../utils/formaters';

const { Text } = Typography;

const ProductsTable = ({ field, form, completeName }) => {
  const component = Form.useWatch(completeName, form);
  const { productos } = component ?? {};

  const calculateTotal = () =>
    productos?.reduce((acc, producto) => {
      const { cantidad = 0, precioUnitario = 0 } = producto ?? {};
      return acc + (cantidad * precioUnitario ?? 0);
    }, 0);

  return (
    <Form.List name={[field.name, 'productos']}>
      {(fields, { add, remove }) => (
        <>
          <Table
            dataSource={fields}
            columns={[
              {
                title: 'ID',
                dataIndex: 'key',
                key: 'key',
                width: 60,
                hidden: true,
                render: (_, __, index) => (
                  <Form.Item hidden name={[index, 'id']} style={{ margin: 0 }}>
                    <Input />
                  </Form.Item>
                ),
              },
              {
                title: 'N°',
                dataIndex: 'number',
                key: 'number',
                width: 60,
                render: (_, __, index) => index + 1,
              },
              {
                title: 'Equipos Involucrados',
                dataIndex: 'equipos',
                key: 'equipos',
                render: (_, __, index) => (
                  <Form.Item
                    name={[index, 'equipos']}
                    rules={[{ required: true, message: 'Campo requerido' }]}
                    style={{ margin: 0 }}
                  >
                    <Input placeholder="Equipos" />
                  </Form.Item>
                ),
              },
              {
                title: 'Descripción',
                dataIndex: 'descripcion',
                key: 'descripcion',
                render: (_, __, index) => (
                  <Form.Item
                    name={[index, 'descripcion']}
                    rules={[{ required: true, message: 'Campo requerido' }]}
                    style={{ margin: 0 }}
                  >
                    <Input placeholder="Descripción" />
                  </Form.Item>
                ),
              },
              {
                title: 'Cantidad',
                dataIndex: 'cantidad',
                key: 'cantidad',
                render: (_, __, index) => (
                  <Form.Item
                    name={[index, 'cantidad']}
                    rules={[{ required: true, message: 'Campo requerido' }]}
                    style={{ margin: 0 }}
                  >
                    <InputNumber
                      min={1}
                      placeholder="Cantidad"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                ),
              },
              {
                title: 'Precio Unitario',
                dataIndex: 'precioUnitario',
                key: 'precioUnitario',
                render: (_, __, index) => (
                  <Form.Item
                    name={[index, 'precioUnitario']}
                    rules={[{ required: true, message: 'Campo requerido' }]}
                    style={{ margin: 0 }}
                  >
                    <InputNumber
                      min={0}
                      placeholder="Precio Unitario"
                      style={{ width: '100%' }}
                      formatter={(value) => `$ ${value}`}
                      parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    />
                  </Form.Item>
                ),
              },
              {
                title: 'Precio Total',
                key: 'pp',
                render: (_, __, index) => {
                  const record = productos?.[index] ?? {};
                  const cantidad = record.cantidad || 0;
                  const precioUnitario = record.precioUnitario || 0;
                  const total = cantidad * precioUnitario;
                  return <Text>${formatMoneyNumber(total)}</Text>;
                },
              },
              {
                title: 'Acciones',
                key: 'actions',
                render: (_, __, index) => (
                  <Button
                    type="link"
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => remove(index)}
                  >
                    Eliminar
                  </Button>
                ),
              },
            ]}
            rowKey="key"
            pagination={false}
            bordered
          />
          <Button
            type="dashed"
            onClick={() => add()}
            block
            icon={<PlusOutlined />}
            style={{ marginTop: 16 }}
          >
            Agregar Producto
          </Button>
          <div style={{ marginTop: 16, textAlign: 'right' }}>
            <Text strong>Total: ${formatMoneyNumber(calculateTotal())}</Text>
          </div>
        </>
      )}
    </Form.List>
  );
};

export default ProductsTable;
