import {
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { Button, Table, Modal, Form, Card, Descriptions, Space } from 'antd';
import Title from 'antd/es/typography/Title';
import ColeccionItemForm from 'components/forms/ColeccionItemForm';
import { useColeccion } from 'providers/ColeccionProvider';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const IDColeccionPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getAllItems, createItem, updateItem, deleteItem, isLoading } =
    useColeccion();

  const [items, setItems] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [coleccion, setColeccion] = useState(null);
  const [form] = Form.useForm();

  const fetchItems = useCallback(async () => {
    const result = await getAllItems(id);
    setItems(result.items);
    setColeccion(result.coleccion);
  }, [getAllItems, id]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const showModal = (item = null) => {
    setCurrentItem(item);
    const initialFormValues = item || null;
    if (coleccion.type === 'TEXTOS') {
      delete initialFormValues.cantidad;
      delete initialFormValues.precio;
      delete initialFormValues.costo;
    }
    form.setFieldsValue(initialFormValues);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentItem(null);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (currentItem) {
        await updateItem(currentItem.id, values);
      } else {
        await createItem(id, values);
      }
      const updatedItems = await getAllItems(id);
      setItems(updatedItems.items);
      setIsModalVisible(false);
      setCurrentItem(null);
    } catch (error) {
      console.error('Failed to save item:', error);
    }
  };

  const handleDelete = async (itemId) => {
    Modal.confirm({
      title: '¿Está seguro que desea eliminar este ítem?',
      okText: 'Sí',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        await deleteItem(itemId);
        const updatedItems = await getAllItems(id);
        setItems(updatedItems.items);
      },
    });
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Descripción',
      dataIndex: 'descripcion',
      key: 'descripcion',
    },
  ];

  if (coleccion?.type === 'PRODUCTOS') {
    columns.push(
      {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        key: 'cantidad',
      },
      {
        title: 'Precio',
        dataIndex: 'precio',
        key: 'precio',
      },
      {
        title: 'Costo',
        dataIndex: 'costo',
        key: 'costo',
      },
      {
        title: 'Tipo de Ítem',
        dataIndex: 'tipo_item',
        key: 'tipo_item',
      }
    );
  }

  columns.push({
    title: 'Acciones',
    key: 'acciones',
    render: (_, record) => (
      <Space>
        {coleccion?.type === 'ACTIVIDADES' && (
          <Button
            type="primary"
            onClick={() =>
              navigate(
                `/admin/colecciones/${id}/items/${record.id}?type=ACTIVIDADES`
              )
            }
            icon={<FileTextOutlined />}
          >
            Actividades
          </Button>
        )}

        <Button
          type="default"
          className="!border-primary text-primary"
          onClick={() => showModal(record)}
          icon={<EditOutlined />}
        >
          {/* Editar */}
        </Button>
        <Button
          type="default"
          onClick={() => handleDelete(record.id)}
          danger
          icon={<DeleteOutlined />}
        >
          {/* Eliminar */}
        </Button>
        {(coleccion?.type === 'PRODUCTOS' || coleccion?.type === 'TEXTOS') && (
          <Button
            type="primary"
            onClick={() =>
              navigate(`/admin/document-composer/${record.id}/presupuesto`)
            }
            icon={<FileTextOutlined />}
          >
            Componer Documento
          </Button>
        )}
      </Space>
    ),
  });

  return (
    <div className="space-y-4">
      {coleccion && (
        <Card title="Detalle de la Colección" className="mb-4">
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Nombre">
              {coleccion.nombre}
            </Descriptions.Item>
            <Descriptions.Item label="Descripción">
              {coleccion.descripcion}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo">{coleccion.type}</Descriptions.Item>
          </Descriptions>
        </Card>
      )}

      <Card className="welcome col-span-3">
        <div className="flex flex-row justify-between">
          <Title level={3} className="text-start">
            <span className="uppercase text-white">Ítems de la Colección</span>
          </Title>
          <Button type="primary" onClick={() => showModal()} className="mb-4">
            Agregar Item
          </Button>
        </div>
      </Card>

      <Table
        dataSource={items}
        columns={columns}
        rowKey="id"
        loading={isLoading}
      />
      <Modal
        title={currentItem ? 'Editar Item' : 'Agregar Item'}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ColeccionItemForm
          form={form}
          initialValues={
            currentItem || {
              nombre: '',
              descripcion: '',
              cantidad: 0,
              precio: 0,
              costo: 0,
              tipo_item:
                coleccion?.type === 'ACTIVIDADES'
                  ? 'ACTIVIDAD'
                  : coleccion?.type === 'PRODUCTOS'
                  ? 'PRODUCTO'
                  : 'TEXTO',
            }
          }
          visibleFields={
            coleccion?.type === 'TEXTOS'
              ? ['nombre', 'descripcion']
              : coleccion?.type === 'ACTIVIDADES'
              ? ['nombre', 'descripcion', 'tipo_item']
              : [
                  'nombre',
                  'descripcion',
                  'cantidad',
                  'precio',
                  'costo',
                  'tipo_item',
                ]
          }
        />
      </Modal>
    </div>
  );
};

export default IDColeccionPage;
