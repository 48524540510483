import { Image } from 'antd';
import React from 'react';
import LogoBlack from '../../assets/logo/LogoBlack';
import LogoProlift from '../../assets/logo/prolift_logo-lg.png';

const Logo = ({ simple = false }) => {
  return (
    <div className="basis-1/5 text-center m-auto">
      {/*{simple ? null : <LogoBlack />}*/}
       <Image  src={LogoProlift} preview={false} className="w-full" />
      {/*<h1 className="p-0 m-0 text-lg xl:text-xl">*/}
      {/*  <span className=" text-primary">PRO</span>{' '}*/}
      {/*  <span className=" text-black">APP</span>*/}
      {/*</h1>*/}
    </div>
  );
};

export default Logo;
