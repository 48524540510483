import { BuildFilled, DeleteFilled, EditFilled } from '@ant-design/icons';
import { Button, Card, Divider, List, Switch, notification } from 'antd';
import ClientDataLinkButton from 'components/links/ClientDataLinkButton';
import { useClientesActions } from 'providers/Clientes/ClientesActionsProvider';
import React, { useState } from 'react';

const ClienteItemList = ({
  usuario,
  openDeleteClient,
  openEditClient,
  getClientProblems,
  handleClientStatus,
}) => {
  // Automatizado para la mantención preventiva?
  const [manPreven, setManPreven] = useState(usuario.automatizadoManPreven);
  const { toggleAutoPreven } = useClientesActions();

  const onChangeManPreven = (checked) => {
    if (usuario.Ascensores?.length === 0) {
      notification.error({
        message:
          'Para activar la opción de mantenimiento preventivo automatizado, el edificio debe tener al menos un ascensor',
      });
      return;
    }
    toggleAutoPreven(usuario.id, checked);
    setManPreven(checked);
  };

  return (
    <List.Item>
      <Card
        title={<span className="capitalize">{usuario.name}</span>}
        actions={[
          <Button
            key={'delete'}
            type="link"
            danger
            onClick={() => openDeleteClient(usuario)}
          >
            <DeleteFilled />
          </Button>,
          <Button
            key={'edit'}
            type="link"
            onClick={() => openEditClient(usuario)}
            className="text-warning"
          >
            <EditFilled />
          </Button>,
          <ClientDataLinkButton
            type="link"
            key={'client_data'}
            idClient={usuario.id}
            icon={<BuildFilled />}
          />,
        ]}
      >
        <div className="-mb-6 space-y-2 py-2">
          <div>
            <span className="lowercase font-semibold">
              {usuario.email || 'Correo no ingresado'}
            </span>
          </div>
          <div className="flex flex-row mt-2 ">
            <Button
              block
              className={`capitalize mr-2 ${
                usuario.active ? ' bg-success hover:bg-green-700' : 'btn-danger'
              }`}
              type="primary"
              // loading={toggleLoading}
              onClick={() => handleClientStatus(usuario)}
            >
              {usuario.active ? 'Activo' : 'Inactivo'}
            </Button>
            <Button
              block
              className={`capitalize ${
                usuario.problems ? ' bg-warning hover:bg-yellow-600' : null
              }`}
              type={usuario.problems ? 'primary' : 'link'}
              disabled={!usuario.problems}
              onClick={() => getClientProblems(usuario)}
            >
              Avisos
            </Button>
          </div>
          {/*<Divider>Automatización</Divider>*/}
          {/*<div className="flex flex-row justify-between">*/}
          {/*  <span>Mantenimiento Preventivo</span>*/}
          {/*  <Switch*/}
          {/*    onChange={(checked) => onChangeManPreven(checked)}*/}
          {/*    value={manPreven}*/}
          {/*  ></Switch>*/}
          {/*</div>*/}
        </div>
      </Card>
    </List.Item>
  );
};

export default ClienteItemList;
