import React from 'react';

import { DocumentosProvider } from 'providers/Administradores/DocumentosProvider';
import { ActionsPresupuestosProvider } from 'providers/Administradores/ActionsPresupuestoProvider';
import { ClientesProvider } from 'providers/Clientes/ClientesProvider';
import { ActionsComprobantesProvider } from 'providers/ActionsComprobantesProvider';
import { ActionsMantencionesDepProvider } from 'providers/ActionsMantencionDepProvider';
import { ActionsODTProvider } from 'providers/ActionsOdtProvider';
import { SolicitudesMantencionProvider } from 'providers/SolicitudesMantencionProvider';
import { ActividadesProvider } from 'providers/ActividadProvider';
import { ClienteProvider } from 'providers/Clientes/ClienteProvider';
import { AscensorEstadosProvider } from 'providers/AscensorEstadosProvider';
import { ClienteMantencionesProvider } from 'providers/ClienteMantencionesProvider';
import { PlantillaTextoProvider } from 'providers/PlantillasTextoProvider';
import { StorageProvider } from 'providers/StorageProvider';
import { EmergenciasProvider } from 'providers/EmergenciasProvider';
import { ColeccionProvider } from '../providers/ColeccionProvider';

const whitAdministradorProvider = (Component) => {
  const WrappedComponent = (props) => (
    <DocumentosProvider>
      <ActionsPresupuestosProvider>
        <ActionsComprobantesProvider>
          <ActionsMantencionesDepProvider>
            <ActionsODTProvider>
              <ClientesProvider>
                <SolicitudesMantencionProvider>
                  <ColeccionProvider>
                    <ActividadesProvider>
                      <ClienteProvider>
                        <AscensorEstadosProvider>
                          <ClienteMantencionesProvider>
                            <PlantillaTextoProvider>
                              <StorageProvider>
                                <EmergenciasProvider>
                                  <Component {...props} />
                                </EmergenciasProvider>
                              </StorageProvider>
                            </PlantillaTextoProvider>
                          </ClienteMantencionesProvider>
                        </AscensorEstadosProvider>
                      </ClienteProvider>
                    </ActividadesProvider>
                  </ColeccionProvider>
                </SolicitudesMantencionProvider>
              </ClientesProvider>
            </ActionsODTProvider>
          </ActionsMantencionesDepProvider>
        </ActionsComprobantesProvider>
      </ActionsPresupuestosProvider>
    </DocumentosProvider>
  );

  WrappedComponent.displayName = `WhitAdministradorProvider(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WrappedComponent;
};

export default whitAdministradorProvider;
