// src/common/providers/ModalProvider.jsx
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState({
    open: false,
    title: '',
    content: null,
    footer: null,
    width: 520,
  });

  const showModal = ({ title, content, footer = null, width = 520 }) => {
    setModalProps({
      open: true,
      title,
      content,
      footer,
      width,
    });
  };

  const hideModal = () => {
    setModalProps({
      open: false,
      title: '',
      content: null,
      footer: null,
      width: 520,
    });
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      <Modal
        open={modalProps.open}
        title={modalProps.title}
        content={modalProps.content}
        footer={modalProps.footer}
        onCancel={hideModal}
        width={modalProps.width}
      />
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
