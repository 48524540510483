// Changelog.jsx
import React, { useState } from 'react';
import ChangelogItem from './components/ChangelogItem';
import { Button, Drawer, Typography } from 'antd';
import useAuth from '../../hooks/useAuth';
import useFilteredChangelog from './hooks/useFilteredChangelog';

const { Title } = Typography;

function Changelog() {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const filteredVersions = useFilteredChangelog(user);

  return (
    <div className="changelog">
      <Button type="link" onClick={() => setOpen(!open)}>
        Ver Cambios
      </Button>
      <Drawer
        title="Changelog"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        width={400}
      >
        {filteredVersions.length > 0 ? (
          filteredVersions.map((version, index) => (
            <ChangelogItem key={index} version={version} />
          ))
        ) : (
          <Title level={5}>No hay cambios para mostrar.</Title>
        )}
      </Drawer>
    </div>
  );
}

export default Changelog;
