import { Modal, notification } from 'antd';
import { useForm } from 'antd/es/form/Form';
import ActividadForm from 'components/forms/ActividadForm';
import ActividadMantencionForm from 'components/forms/ActividadMantenciónForm';
import ActivityMaintanceForm from 'components/forms/ActivityMaintanceForm';
import EliminarForm from 'components/forms/EliminarForm';
import ImportActividadesForm from 'components/forms/ImportActividadesForm';
import { useAppApi, usePAppApi } from 'hooks/useAppApi';
import useRequest from 'hooks/useRequest';
import { createContext, useCallback, useContext, useState } from 'react';
import { useColeccion } from './ColeccionProvider';

const ActividadesContext = createContext(null);
// todo: Se debe manejar el caso donde se agregue una nueva actividad y que esta se agregue a los clientes que tienen asociada la colecicón
const defaultModalProps = {
  children: null,
  title: 'Error',
  open: false,
};

export const ActividadesProvider = ({ children, id }) => {
  const [modalProps, setModalProps] = useState(defaultModalProps);
  const { getAllItems } = useColeccion();

  const map = useCallback((result) => {
    return result;
  }, []);

  const {
    data,
    isLoading: loading,
    error,
    hasCalled,
    call: notFunctionalCall,
  } = useAppApi({
    baseUrl: '/actividad/clientes',
    mapResults: map,
  });

  const call = useCallback(
    async (id_client, template = false) => {
      const result = await notFunctionalCall({
        extUrl: `/${id_client}`,
        queryParams: {
          template,
        },
      });

      return result;
    },
    [notFunctionalCall]
  );

  //   const { call: NFaddTenant } = usePAppApi({
  //     url: '/Actividades',
  //     mapResults: useCallback((data) => {
  //       console.log(data);
  //       return data;
  //     }, []),
  //   });
  const { request, data: reqData, errors, isLoading } = useRequest();
  const [isLoadingAdd, setIsLoadingAdd] = useState(false);
  const ADD_URL = '/actividad';

  const [form] = useForm();
  const add = useCallback(
    async (values) => {
      setIsLoadingAdd(true);
      try {
        await form.validateFields();
        if (values.tipo_intervalo?.length === 1) {
          let postValues = {
            ...values,
            tipo_intervalo: values.tipo_intervalo[0],
          };
          const response = await request(ADD_URL, 'POST', postValues);
          if (values?.id_cliente) {
            call(values?.id_cliente);
          }
          setModalProps(defaultModalProps);
          setIsLoadingAdd(false);
          return response;
        } else {
          notification.error({
            message: 'Error al agregar la actividad',
          });
          setIsLoadingAdd(false);
        }
      } catch (error) {
        setIsLoadingAdd(false);
        notification.error({
          message: 'Error al agregar la actividad',
          description: error.message,
        });
      }
    },
    [request, form, call]
  );
  const edit = useCallback(
    async (values, id) => {
      try {
        // console.log(values, id, 'edit');
        await form.validateFields();
        if (values.tipo_intervalo?.length === 1) {
          let postValues = {
            ...values,
            tipo_intervalo: values.tipo_intervalo[0],
          };
          const response = await request(`${ADD_URL}/${id}`, 'PUT', postValues);
          if (values?.id_cliente) {
            call(values?.id_cliente);
          }
          setModalProps(defaultModalProps);
          return response;
        } else {
          notification.error({
            message: 'Error al agregar la actividad',
          });
        }
      } catch (error) {
        notification.error({
          message: 'Error al editar la actividad',
          description: error.message,
        });
      }
    },
    [request, form, call]
  );
  const deleteActividad = async (id, id_cliente, refresh) => {
    const response = await request(`${ADD_URL}/${id}`, 'DELETE');
    setModalProps(defaultModalProps);
    if (refresh) {
      refresh();
    }
    if (id_cliente) {
      call(id_cliente);
    }
    return response;
  };
  const openAdd = async (
    id_cliente,
    isTemplate = false,
    idItem = null,
    refresh
  ) => {
    setModalProps({
      open: true,
      children: (
        <ActividadMantencionForm
          form={form}
          isLoadingAdd={isLoadingAdd}
          onFinish={async (values) => {
            const response = await add(values);
            if (response && refresh) {
              refresh();
            }
            return response;
          }}
          layout="vertical"
          initialValues={{
            tipo_intervalo: ['meses'],
            id_cliente: id_cliente,
            type: 'mantencion',
            isTemplate: isTemplate,
            id_item_coleccion: idItem,
          }}
        />
      ),
      title: 'Agregar Actividad',
    });
  };
  const openEdit = async (values, refresh) => {
    setModalProps({
      open: true,
      children: (
        <ActividadMantencionForm
          form={form}
          onFinish={async (valuesForm) => {
            const response = await edit(valuesForm, values.id);
            if (response && refresh) {
              refresh();
            }
            return response;
          }}
          layout="vertical"
          initialValues={{
            ...values,
            Fechas: values?.Fechas?.map((fecha) => Number(fecha.mes)),
            tipo_intervalo: ['meses'],
          }}
          key={values.id}
        />
      ),
      title: 'Agregar Actividad',
    });
  };
  const openDelete = async (id, id_cliente, refresh) => {
    setModalProps({
      open: true,
      children: (
        <EliminarForm
          title="Eliminar Actividad"
          id={id}
          deleteRegister={async (id) => {
            const response = await deleteActividad(id, id_cliente, refresh);
            if (refresh) {
              refresh();
            }
            return response;
          }}
        />
      ),
      title: 'Agregar Actividad',
    });
  };
  const openImport = async (id_cliente, refresh) => {
    setModalProps({
      open: true,
      children: (
        <ImportActividadesForm
          id={id_cliente}
          onFinish={(data, idItem) => {
            // getAllItems(idItem);
            refresh(idItem);
            setModalProps(defaultModalProps);
          }}
        />
      ),
      title: 'Importar Actividades',
      width: 700,
    });
  };

  return (
    <>
      <Modal
        footer={false}
        onCancel={() => setModalProps(defaultModalProps)}
        {...modalProps}
      />
      <ActividadesContext.Provider
        value={{
          call,
          id,
          loading,
          error,
          hasCalled,
          data,
          openAdd,
          isLoadingAdd: isLoading(ADD_URL),
          openEdit,
          openDelete,
          openImport,
        }}
      >
        {children}
      </ActividadesContext.Provider>
    </>
  );
};

export const useActividades = () => useContext(ActividadesContext);
