import { formatMoneyNumber } from './formaters';
import { IVA } from '../CONSTANTS/CONSTANTS';
import { calculateDimensions, getImage, getUrlFromFileItem } from './getters';
import dayjs from 'dayjs';

/**
 * Función principal para preparar los datos para el documento DOCX.
 * @param {Object} values - Valores del formulario.
 * @returns {Object} response - Datos preparados para el documento.
 */
export const prepareDocxData = async (values) => {
  // Clonar el objeto 'values' para evitar mutaciones directas
  let response = { ...values };
  let bodyFields = [];

  response.date = dayjs(response.createdOn).isValid()
    ? dayjs(response.createdOn).format('YYYY-MM-DD')
    : null;

  // Inicializar el array que contendrá las secciones procesadas
  const loop = [];

  // Iterar sobre cada campo en 'values.fields'
  for (const field of values.fields) {
    // Verificar si el campo tiene hijos
    if (field?.children) {
      // for donde salga el indx del campo tambi´ne
      for (let i = 0; i < field.children.length; i++) {
        const child = field.children[i];
        // Ignorar elementos marcados como 'deleted'
        bodyFields.push(child);
        if (child.deleted) continue;
        // Procesar según el tipo de 'child'
        switch (field.prefabric) {
          // OJO, tenemos un prefabric case, el introducción, dentro de la lógica de lista
          case 'finalizacion':
            response = procesarFinalizacion(child, loop, response);
            continue;
          default:
            break;
        }
        switch (child.type) {
          case 'image':
            bodyFields[i] = await procesarImage(child, loop); // Espera a que termine de procesar la imagen
            break;
          case 'lista':
            procesarLista(child, loop, response, field);
            break;
          case 'productos':
            procesarProductos(child, loop, values.fields.indexOf(field));
            break;
          case 'section':
            procesarSection(child, loop);
            break;
          case 'conceptList':
            procesarConceptList(child, loop);
            break;
          default:
            // Manejar tipos desconocidos si es necesario
            break;
        }
      }
    }
  }

  // Asignar el array 'loop' al objeto de respuesta
  response.loop = loop;

  return response;
};

const procesarFinalizacion = (child, loop, response) => {
  let temp = {
    finish: true,
    finishTitle: child.title,
    finishContent: child.description,
  };
  response = { ...response, ...temp };
  return response;
};
/**
 * Procesa elementos de tipo 'image' y los agrega al array 'loop'.
 * @param {Object} child - Elemento hijo de tipo 'image'.
 * @param {Array} loop - Array que acumula las secciones procesadas.
 */

const getImageSize = async (arrayBuffer) => {
  return new Promise((resolve, reject) => {
    const blob = new Blob([arrayBuffer]);
    const img = new Image();
    img.src = URL.createObjectURL(blob);
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
      URL.revokeObjectURL(img.src); // Limpiar el recurso de memoria
    };
    img.onerror = (error) => reject(error);
  });
};

const procesarImage = async (child, loop) => {
  const { image } = child;
  const path = image[0].response.path;
  const childData = image[0];

  delete childData.thumbUrl;

  const response = await getImage(path, 'image');

  // Obtén el tamaño original de la imagen
  const { width, height } = await getImageSize(response);

  // Calcula las nuevas dimensiones según los criterios
  const { width: adjustedWidth, height: adjustedHeight } = calculateDimensions(
    width,
    height
  );

  loop.push({
    image: {
      buffer: response,
      width: adjustedWidth,
      height: adjustedHeight,
    },
    isImage: true,
    caption: child.caption,
  });
  return [childData];
};
/**
 * Procesa elementos de tipo 'lista' y los agrega al array 'loop'.
 * @param {Object} child - Elemento hijo de tipo 'lista'.
 * @param {Array} loop - Array que acumula las secciones procesadas.
 * @param {Object} response - Objeto de respuesta que puede ser modificado.
 */
const procesarLista = (child, loop, response, field) => {
  // Mapear los ítems de la lista
  const elementos = child.items.map((item) => ({
    textList: item.text,
  }));
  // Verificar si ya se ha asignado una introducción
  if (!response.introduction && field.prefabric === 'introduction') {
    response.introduction = true;
    response.introductionFields = elementos;
  } else {
    // Agregar la lista al array 'loop'
    loop.push({
      isList: true,
      bullets: child.listType === 'bullets',
      numbered: child.listType === 'numbered',
      elementos,
    });
  }
};

/**
 * Procesa elementos de tipo 'productos' y los agrega al array 'loop'.
 * @param {Object} child - Elemento hijo de tipo 'productos'.
 * @param {Array} loop - Array que acumula las secciones procesadas.
 * @param {number} fieldIndex - Índice del campo actual en 'values.fields'.
 */
const procesarProductos = (child, loop, fieldIndex) => {
  let totalGeneral = 0;

  // Mapear los productos y calcular totales
  const productosProcesados = child.productos.map((producto) => {
    const total = producto.cantidad * producto.precioUnitario;
    totalGeneral += total;

    return {
      numero: fieldIndex + 1, // Considera si este índice es el deseado
      equipos: producto.equipos || '',
      descripcion: producto.descripcion || '',
      cantidad: producto.cantidad || 0,
      precioUnitario: formatMoneyNumber(producto.precioUnitario) || '0',
      total: formatMoneyNumber(total),
    };
  });

  // Agregar la sección de productos al array 'loop'
  loop.push({
    isProductos: true,
    productos: productosProcesados,
    totalSinIva: formatMoneyNumber(totalGeneral),
    totalConIva: formatMoneyNumber(totalGeneral * (1 + IVA)),
  });
};

/**
 * Procesa elementos de tipo 'section' y los agrega al array 'loop'.
 * @param {Object} child - Elemento hijo de tipo 'section'.
 * @param {Array} loop - Array que acumula las secciones procesadas.
 */
const procesarSection = (child, loop) => {
  loop.push({
    title: child.title,
    description: child.description,
    isSection: true,
    isDescription: !!child.description,
    isPrimary: child.titleType === 'principal',
    isSecondary: child.titleType === 'secundario',
  });
};

/**
 * Procesa elementos de tipo 'conceptList' y los agrega al array 'loop'.
 * @param {Object} child - Elemento hijo de tipo 'conceptList'.
 * @param {Array} loop - Array que acumula las secciones procesadas.
 */
const procesarConceptList = (child, loop) => {
  loop.push({
    conceptList: child.conceptList,
    isConceptList: true,
  });
};
