import React, { useCallback, useEffect, useState } from 'react';
import { Form, Button, Card } from 'antd';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import ImageModule from 'open-docxtemplater-image-module-2';
import { usePresupuestos } from '../../../providers/Presupuestos/Presupuestos';
import { useParams } from 'react-router-dom';
import DocumentoForm from './DocumentoForm';
import ClienteForm from './ClienteForm';
import DocumentComposer from '../../../components/DocumentComposer/DocumentComposer';
import { prepareDocxData } from '../../../utils/prepareData';
import dayjs from 'dayjs';
import Title from 'antd/es/typography/Title';

const LOCAL_STORAGE_KEY = 'documentosForm';

function Documentos() {
  const { retrieve, update } = usePresupuestos();
  const [presupuesto, setPresupuesto] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [form] = Form.useForm();

  const updateForm = useCallback(
    async (response) => {
      setPresupuesto(response);
      setLoading(false);
      const initialValues = response.jsonDocument;
      initialValues.createdOn = initialValues?.createdOn
        ? dayjs(initialValues?.createdOn)
        : null;
      form.setFieldsValue({
        ...response?.Cliente,
        idDoc: response?.id,
        reason: response?.reason,
        ...initialValues,
      });
    },
    [form]
  );

  // useEffect para cargar datos del servidor y de localStorage
  useEffect(() => {
    setLoading(true);
    retrieve(id)
      .then((response) => {
        setPresupuesto(response);
        setLoading(false);
        const initialValues = response.jsonDocument || {};

        initialValues.createdOn = initialValues?.createdOn
          ? dayjs(initialValues?.createdOn)
          : null;
        form.setFieldsValue({
          ...response?.Cliente,
          idDoc: response?.id,
          reason: response?.reason,
          ...initialValues,
        });
      })
      .catch((error) => {
        console.log(error);
        console.error('Error al recuperar el presupuesto:', error);
        setLoading(false);
      });
  }, [retrieve, id, form]);

  const onFinish = async (values) => {
    const data = await prepareDocxData(values);

    const updateRes = await update(id, {
      jsonDocument: data,
    });
    const response = updateRes.data;

    const initialValues = response.jsonDocument || {};

    initialValues.createdOn = initialValues?.createdOn
      ? dayjs(initialValues?.createdOn)
      : null;
    form.setFieldsValue({
      ...response?.Cliente,
      idDoc: response?.id,
      reason: response?.reason,
      ...initialValues,
    });
    setPresupuesto(response);

    // Guardar los valores del formulario en localStorage
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(values));

    try {
      // Carga la plantilla .docx
      const response = await fetch('/template.docx');
      const arrayBuffer = await response.arrayBuffer();
      const zip = new PizZip(arrayBuffer);

      // Obtén todas las imágenes procesadas de data.loop
      const imageArrayBuffers = await Promise.all(
        data.loop
          .filter((item) => item.image) // Solo procesa los elementos que contienen imágenes
          .map(async (item) => await item.image) // Asume que cada imagen ya es un ArrayBuffer
      );

      // Configuración de opciones para el módulo de imagen
      let currentImageIndex = 0;
      const imageOptions = {
        centered: true,
        getImage: (image) => {
          return image.buffer;
        },
        getSize: (image) => {
          const imageBuffer = imageArrayBuffers[currentImageIndex];
          currentImageIndex++;
          return [imageBuffer.width, imageBuffer.height];
        },
      };

      const imageModule = new ImageModule(imageOptions);

      // Configuración de docxtemplater con el módulo de imagen
      const doc = new Docxtemplater(zip, {
        paragraphLoop: false,
        linebreaks: true,
        modules: [imageModule], // Agrega el módulo de imagen
      });
      // Cargar los datos en el documento, incluida la imagen
      doc.setData({ ...data });

      // Renderizar el documento
      try {
        doc.render();
      } catch (renderError) {
        console.error('Error al renderizar el documento:', renderError);
        throw renderError;
      }

      // Generar y descargar el documento en formato .docx
      const out = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      saveAs(out, `NDR-${id}.docx`);
    } catch (error) {
      console.error('Error al generar el documento:', error);
    }
  };

  // Manejador para cuando la validación falla
  const onFinishFailed = (errorInfo) => {
    const { errorFields } = errorInfo;
    if (errorFields && errorFields.length > 0) {
      // Desplazarse al primer campo con error
      form.scrollToField(errorFields[0].name, {
        behavior: 'smooth', // Opcional: para un desplazamiento suave
        block: 'center', // Opcional: para centrar el campo en la vista
      });
    }
  };

  return (
    <div className="space-y-4">
      <Card>
        <Title level={3}>Documento N°{id}</Title>
      </Card>
      {!loading && presupuesto ? (
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
        >
          <div className="space-y-2">
            <Card title="Datos del Documento">
              {!loading ? <DocumentoForm /> : null}
            </Card>
            <Card title="Datos del cliente" hoverable loading={loading}>
              {!loading ? <ClienteForm data={presupuesto?.Cliente} /> : null}
            </Card>
            <DocumentComposer editable form={form} />
            <Form.Item style={{ marginTop: 16 }}>
              <Button type="primary" htmlType="submit">
                Guardar y Descargar Documento
              </Button>
            </Form.Item>
          </div>
        </Form>
      ) : null}
    </div>
  );
}

export default Documentos;
