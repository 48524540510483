import React from 'react';
import { Tour } from 'antd';

const EdificiosTour = ({ open, onClose, columnRefs }) => {
  const steps = [
    {
      title: 'ID',
      description: (
        <>
          Esta es la columna de <strong>ID</strong> donde se muestra el{' '}
          <strong>identificador único</strong> de cada edificio.
        </>
      ),
      target: () => columnRefs.idRef.current,
    },
    {
      title: 'Nombre',
      description: (
        <>
          Aquí se muestra el <strong>nombre del edificio</strong>. Puedes buscar
          edificios por nombre usando el <strong>filtro</strong>.
        </>
      ),
      target: () => columnRefs.nameRef.current,
    },
    {
      title: 'Ascensores',
      description: (
        <>
          <strong>Número de ascensores</strong> en el edificio. No siempre
          coincide exactamente con los edificios registrados. Si el número de
          edificios registrados es menor que el número de ascensores indicados,
          por favor, solicita la actualización a <strong>administración</strong>
          .
        </>
      ),
      target: () => columnRefs.nAscensoresRef.current,
    },
    {
      title: 'Dirección',
      description: (
        <>
          <strong>Dirección física</strong> del edificio.
        </>
      ),
      target: () => columnRefs.addressRef.current,
    },
    {
      title: 'Comuna',
      description: (
        <>
          <strong>Comuna</strong> donde se encuentra ubicado el edificio.
        </>
      ),
      target: () => columnRefs.communeRef.current,
    },
    {
      title: 'Creado En',
      description: (
        <>
          <strong>Fecha de creación</strong> del registro del edificio.
        </>
      ),
      target: () => columnRefs.createdAtRef.current,
    },
    {
      title: 'Actualizado En',
      description: (
        <>
          <strong>Fecha de la última actualización</strong> del registro del
          edificio.
        </>
      ),
      target: () => columnRefs.updatedAtRef.current,
    },
    {
      title: 'Acciones',
      description: (
        <>
          <strong>Acciones disponibles</strong> para cada edificio, como{' '}
          <strong>ver más detalles</strong>.
        </>
      ),
      target: () => columnRefs.actionsRef.current,
      placement: 'left',
    },
    {
      title: 'Datos del edificio',
      description: (
        <>
          Aquí podrás ver más <strong>detalles del edificio</strong>, sus{' '}
          <strong>ascensores</strong>,<strong>plan de mantenimiento</strong> y
          la <strong>carpeta digital</strong>, donde podrás visualizar y agregar
          archivos según sea necesario.
        </>
      ),
      target: () => columnRefs.viewRef.current,
      placement: 'left',
    },
  ];

  return (
    <Tour
      open={open}
      onClose={onClose}
      steps={steps}
      placement="bottom"
      mask={true}
      type="primary"
      gap={{
        radius: 10,
      }}
    />
  );
};

export default EdificiosTour;
