import React, { useState, useEffect } from 'react';
import { Table, Tooltip, Select, Modal, Button } from 'antd';

const ScheduleTable = ({ data }) => {
  // Estado para filtros y modal
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalActivity, setModalActivity] = useState(null);

  // Definir colores para cada categoría
  const categoryColorClasses = {
    'Sin categoría': 'bg-gray-500',
    Cabina: 'bg-green-500',
    Recepción: 'bg-blue-500',
    Control: 'bg-orange-500',
    'Sistema de Tracción': 'bg-red-500',
    Escotilla: 'bg-purple-500',
    Pozo: 'bg-yellow-800',
    Edificio: 'bg-yellow-500',
    'Sala de máquinas': 'bg-cyan-500',
    'Techo cabina/Escotilla/Pozo': 'bg-pink-500',
  };

  // Meses del año
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  // Procesar datos al iniciar el componente o cuando cambia el filtro
  useEffect(() => {
    const processedData = processData();
    setTableData(processedData);
  }, [data, selectedCategory]);

  // Función para procesar datos y aplicar el filtro
  const processData = () => {
    let activitiesList = [];

    data.forEach((activity) => {
      // Obtener el nombre de la categoría (sector)
      const sector = activity.Categoria.nombre || 'Sin categoría';

      // Aplicar filtro de categoría si está seleccionado
      if (selectedCategory && sector !== selectedCategory) {
        return;
      }

      // Obtener los meses en los que se realiza la actividad
      const intervalos = activity.Fechas.map((fecha) => fecha.mes);

      const row = {
        key: activity.id,
        sector: sector, // El sector es la categoría
        nombre: activity.title || 'Sin título',
        resumen: activity.resumen || '',
        // obligatorio: activity.obligatorio,
        repetitividad: activity.tipo_intervalo || 'No especificado',
        intervalos: intervalos,
        estado: activity.estado || 'No especificado',
        // Generar campos para cada mes
        ...months.reduce((acc, month, index) => {
          acc[`mes_${index + 1}`] = intervalos.includes(index + 1);
          return acc;
        }, {}),
      };

      activitiesList.push(row);
    });

    return activitiesList;
  };

  // Opciones para filtros
  const categories = [...new Set(data.map((item) => item.Categoria.nombre))];

  // Funciones de manejo de filtros
  const handleCategoryFilter = (value) => {
    setSelectedCategory(value);
  };

  // Función para mostrar detalles de la actividad
  const showActivityDetails = (activity) => {
    setModalActivity(activity);
    setIsModalVisible(true);
  };

  // Función para renderizar celdas de mes
  const renderMonthCell = (value, activity, month) => {
    const isScheduled = activity.intervalos.includes(month);

    if (isScheduled) {
      const colorClass = categoryColorClasses[activity.sector] || 'bg-gray-500';
      return (
        <Tooltip title={activity.resumen}>
          <div
            className={`w-full h-full ${colorClass}`}
            style={{ minHeight: '20px' }}
          ></div>
        </Tooltip>
      );
    } else {
      return (
        <div
          className="w-full h-full bg-gray-200"
          style={{ minHeight: '20px' }}
        ></div>
      );
    }
  };

  // Definir columnas de la tabla
  const columns = [
    {
      title: 'Actividad',
      dataIndex: 'nombre',
      key: 'nombre',
      fixed: 'left',
      width: 200,
      render: (text, record) => (
        <span
          onClick={() => showActivityDetails(record)}
          className="cursor-pointer text-blue-600"
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Sector',
      dataIndex: 'sector',
      key: 'sector',
      fixed: 'left',
      width: 150,
    },
    ...months.map((month, index) => ({
      title: month,
      dataIndex: `mes_${index + 1}`,
      key: `mes_${index + 1}`,
      width: 80,
      render: (value, record) => renderMonthCell(value, record, index + 1),
    })),
  ];

  return (
    <div className="p-4">
      {/* Filtro por categoría */}
      <div className="flex flex-wrap gap-4 mb-4">
        <Select
          placeholder="Filtrar por Categoría"
          onChange={handleCategoryFilter}
          allowClear
          className="w-full md:w-1/3"
        >
          {categories.map((category) => (
            <Select.Option key={category} value={category}>
              {category}
            </Select.Option>
          ))}
        </Select>
      </div>

      {/* Leyenda de colores */}
      <div className="flex flex-wrap items-center space-x-4 mb-4">
        {Object.entries(categoryColorClasses).map(([categoria, colorClass]) => (
          <div key={categoria} className="flex items-center">
            <div className={`w-4 h-4 ${colorClass} mr-2`}></div>
            <span>{categoria}</span>
          </div>
        ))}
      </div>

      {/* Tabla */}
      <div className="overflow-auto">
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          className="min-w-full"
          scroll={{ x: 'max-content' }}
        />
      </div>

      {/* Modal de detalles */}
      <Modal
        open={isModalVisible}
        title={modalActivity?.nombre}
        onCancel={() => setIsModalVisible(false)}
        footer={false}
        // footer={[
        //   <Button key="edit" onClick={() => editActivity(modalActivity)}>
        //     Editar
        //   </Button>,
        //   <Button key="delete" danger onClick={() => deleteActivity(modalActivity)}>
        //     Eliminar
        //   </Button>,
        // ]}
      >
        <p>
          <strong>Resumen:</strong> {modalActivity?.resumen}
        </p>
        {/*<p>*/}
        {/*  <strong>Obligatorio:</strong>{' '}*/}
        {/*  {modalActivity?.obligatorio ? 'Sí' : 'No'}*/}
        {/*</p>*/}
        {/*<p>*/}
        {/*  <strong>Repetitividad:</strong> {modalActivity?.repetitividad}*/}
        {/*</p>*/}
        <p>
          <strong>Estado:</strong> {modalActivity?.estado}
        </p>
        <p>
          <strong>Categoría:</strong> {modalActivity?.sector}
        </p>
      </Modal>
    </div>
  );
};

export default ScheduleTable;
