import {
  DownloadOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Modal, Table, Tag, Input, Space } from 'antd';
import {
  SOLICITUD_TIPOS,
  SOLICITUD_TIPOS_ARRAY,
  getColorForStatus,
} from 'assets/data/SolicitudesEstados';
import { useCalendarActions } from 'providers/CalendarSolc/CalendarActionsProvider';
import { useSolicitudesMantencion } from 'providers/SolicitudesMantencionProvider';
import { useSolicitudes } from 'providers/SolicitudesProvider';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const SolicitudesTable = () => {
  const { data, hasCalled, call, isLoading, addSolicitudToDay } =
    useSolicitudes();
  const { openDowloadMantencion } = useSolicitudesMantencion();
  const { selectedDay } = useCalendarActions();

  const handleClickInSolc = (record) => {
    if (
      [SOLICITUD_TIPOS.ENVIADA, SOLICITUD_TIPOS.FINALIZADA].includes(
        record.status
      )
    ) {
      openDowloadMantencion(record);
    } else {
      addSolicitudToDay(record, selectedDay);
    }
  };

  useEffect(() => {
    if (!hasCalled) {
      call();
    }
  }, [hasCalled, call]);

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  // Estado para el término de búsqueda
  const [searchText, setSearchText] = useState('');

  // Filtrar los datos basados en el término de búsqueda
  const filteredData = useMemo(() => {
    if (!searchText) return data;

    return data.filter((solicitud) =>
      solicitud.Cliente?.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [data, searchText]);

  const { clientNames } = useMemo(() => {
    // Inicializa el objeto con arrays vacíos para los nombres.
    const initial = { clientNames: [] };

    const result = data?.reduce((acc, solicitud) => {
      // Verifica si el nombre ya existe en el acumulador.
      if (
        solicitud.Cliente?.name &&
        !acc.clientNames.some((item) => item.value === solicitud.Cliente?.name)
      ) {
        acc.clientNames.push({
          value: solicitud.Cliente?.name,
          text: solicitud.Cliente?.name,
        });
      }

      return acc;
    }, initial);

    return {
      clientNames: result?.clientNames || [],
    };
  }, [data]);

  const columns = [
    {
      title: 'Mantención',
      dataIndex: 'id_mantencion',
      key: 'id_mantencion',
      render: (id_mantencion) =>
        id_mantencion ? (
          <Link to={`/admin/mantenciones/${id_mantencion}?only_edit=1`}>
            {'N° ' + id_mantencion}
          </Link>
        ) : (
          'NA'
        ),
      width: 100,
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.id_mantencion - b.id_mantencion,
      showSorterTooltip: false,
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      width: 100,
      render: (estado) => {
        return (
          <Tag color={getColorForStatus(estado)}>{estado?.toUpperCase()}</Tag>
        );
      },
      filters: SOLICITUD_TIPOS_ARRAY.map((estado) => ({
        value: estado,
        text: estado,
      })),
      onFilter: (value, record) => record.status === value,
      defaultFilteredValue: [SOLICITUD_TIPOS.SIN_ASIGNAR],
    },
    {
      title: 'Cliente',
      dataIndex: 'Cliente',
      render: (data) => (
        <span>
          {data?.name} ({data?.rut})<br />
          {data?.address}, {data?.commune}
        </span>
      ),
      width: 250,
      // Eliminamos los filtros y onFilter para la columna "Cliente"
    },
    {
      title: 'Encargado',
      dataIndex: 'encargado',
      width: 150,
    },
    {
      title: 'Creado',
      dataIndex: 'createdAt',
      render: (createdAt) => moment(createdAt).format('YYYY-MM-DD'),
      width: 100,
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      render: (_, record) => (
        <div className="space-x-2">
          <Button onClick={() => handleClickInSolc(record)} type="link">
            {[SOLICITUD_TIPOS.FINALIZADA, SOLICITUD_TIPOS.ENVIADA].includes(
              record.status
            ) ? (
              <>
                <DownloadOutlined /> DESCARGAR/ENVIAR
              </>
            ) : (
              <>
                <PlusOutlined /> AGREGAR
              </>
            )}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal open={open} onCancel={closeModal} footer={false}></Modal>

      {/* Buscador por Nombre de Cliente */}
      <div className="mb-4">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Input
            placeholder="Buscar por nombre de cliente"
            allowClear
            enterButton={<SearchOutlined />}
            size="middle"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
        </Space>
      </div>

      <Table
        loading={isLoading}
        size="small"
        className="h-full"
        scroll={{ x: 'calc(700px + 50%)' }}
        dataSource={filteredData}
        columns={columns}
        rowKey="id_mantencion" // Asegúrate de que 'id_mantencion' es único
      />
    </>
  );
};

export default SolicitudesTable;
