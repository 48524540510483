import Home from '../../pages/admin/Home';
import SignIn from '../../pages/SignIn';
import Users from 'pages/admin/Users';
import Error404 from '../../pages/admin/Error404';
import Client from '../../pages/admin/Client';
import Main from '../../pages/Main';
import Folders from '../../pages/admin/Folders/Folders';
import BudgetPageContainer from '../../Containers/BudgetPageContainer/BudgetPageContainer';
import MaintanceReportsAdmin from '../../pages/admin/MaintanceReports/MaintanceReports';
import AddBudgetForm from 'components/admin/Budget/AddBudgetForm';
import Certificacion from 'pages/admin/Certificacion';
import AddCertification from 'Containers/AddCertification';
import CertificationDetail from 'components/applications/CertificationDetail';
import LayoutMain from 'components/layouts/LayoutMain';
import DatosCliente from 'pages/DatosCliente';
import HomeSpecialist from 'pages/Specialist/Home/Home';
import OrderDetail from 'pages/Specialist/OrderDetail';
import MaintanceReports from 'pages/Specialist/MaintanceReports';
import AttentionVoucher from 'pages/Specialist/AttentionVoucher';
import FinishOrderForm from 'components/specialist/Order/FinishOrderForm';
import FormFinishMaintanceReport from 'components/specialist/MaintanceReports/FormFinishMaintanceReport/FormFinishMaintanceReport';
import Test from 'pages/Specialist/Test';
import SpecialistOrders from 'pages/Specialist/SpecialistOrders';
import OneMaintanceReport from 'pages/Specialist/OneMaintanceReport';
import Maintance from 'pages/admin/Maintance/Maintance';
import Category from 'pages/admin/Maintance/Category';
import Activities from 'pages/admin/Maintance/Activities';
import Asignations from 'pages/admin/Maintance/Asignations';
import LayoutLogin from 'components/layouts/LayoutLogin';
import MaintanceToDo from 'pages/Specialist/MaintanceToDo';
import Mantenciones from 'pages/admin/Mantenciones';
import ListaMantenciones from 'pages/Specialist/lista-mantenciones';
import Administradores from 'pages/admin/Administradores';
import Mantencion from 'pages/Specialist/mantenciones/Mantencion';
import MantencionesCalendario from 'pages/admin/MantencionesCalendario';
import IDClientePage from 'pages/admin/IDClientePage/IDClientePage';
import AscensoresPage from 'pages/admin/IDClientePage/AscensoresPage';
import ColeccionesPage from 'pages/admin/ColeccionesPage/ColeccionesPage';
import IDColeccionPage from 'pages/admin/ColeccionesPage/IDColeccionPage';
import DocumentComposerPage from 'pages/admin/ColeccionesPage/DocumentComposerPage';
import CompositorPresupuestos from 'pages/admin/CompositorPresupuestosPage';
import PresupuestosPage from 'pages/admin/PresupuestosPage/PresupuestosPage';
import ItemsColeccionPage from 'pages/admin/ColeccionesPage/ItemsColeccionPage';
import IDClientePageSpecialist from 'pages/Specialist/IDClientePage';
import Documentos from '../../pages/admin/Documentos/Documentos';
import EdificiosPage from '../../features/Especialistas/Pages/Edificios/EdificiosPage';

export const routesSpecialist = [
  {
    path: '/specialist',
    layout: LayoutMain,
    element: HomeSpecialist,
  },
  {
    path: '/specialist/mantenciones/:id',
    layout: LayoutMain,
    element: Mantencion,
  },
  {
    path: '/',
    layout: LayoutMain,
    element: HomeSpecialist,
  },
  {
    path: '/specialist/AttentionVoucher',
    layout: LayoutMain,
    element: AttentionVoucher,
  },
  {
    path: '/specialist/lista-mantenciones',
    layout: LayoutMain,
    element: ListaMantenciones,
  },
  {
    path: '/specialist/lista-mantenciones/:idMantencion',
    layout: LayoutMain,
    element: MaintanceToDo,
  },
  {
    path: '/specialist/order',
    layout: LayoutMain,
    element: SpecialistOrders,
  },
  {
    path: '/specialist/order/:id',
    layout: LayoutMain,
    element: OrderDetail,
  },
  {
    path: '/specialist/order/:id/finish-form',
    layout: LayoutMain,
    element: FinishOrderForm,
  },
  {
    path: '/specialist/maintanceReports',
    layout: LayoutMain,
    element: MaintanceReports,
  },
  {
    path: '/specialist/maintanceReports/:id',
    layout: LayoutMain,
    element: OneMaintanceReport,
  },

  {
    path: '/specialist/maintanceReports/:id/finish-form',
    layout: LayoutMain,
    element: FormFinishMaintanceReport,
  },
  {
    path: '/specialist/edificios',
    layout: LayoutMain,
    element: EdificiosPage,
  },
  {
    path: '/specialist/edificios/:id',
    layout: LayoutMain,
    element: IDClientePageSpecialist,
  },

  {
    path: '/*',
    layout: LayoutMain,
    element: Error404,
  },
  {
    path: '/specialist/test',
    layout: LayoutMain,
    element: Test,
  },
];
export const routesAuth = [
  {
    path: '/login',
    layout: LayoutLogin,
    element: SignIn,
  },
];
export const routesMain = [
  {
    path: '/',
    layout: LayoutMain,
    element: Main,
  },
];

export const routesAdmin = [
  {
    path: '/admin/users',
    layout: LayoutMain,
    element: Users,
  },
  {
    path: '/admin/presupuestos/:id/documentos',
    layout: LayoutMain,
    element: Documentos,
  },
  {
    path: '/admin/administradores',
    layout: LayoutMain,
    element: Administradores,
  },
  {
    path: '/admin/maintance',
    layout: LayoutMain,
    element: Maintance,
  },
  {
    path: '/admin/mantenciones',
    layout: LayoutMain,
    element: Mantenciones,
  },
  {
    path: '/admin/colecciones',
    layout: LayoutMain,
    element: ColeccionesPage,
  },
  {
    path: '/admin/colecciones/:id',
    layout: LayoutMain,
    element: IDColeccionPage,
  },
  {
    path: '/admin/colecciones/:id/items/:itemId',
    layout: LayoutMain,
    element: ItemsColeccionPage,
  },
  {
    path: '/admin/document-composer/:id/:tipoDocumento',
    layout: LayoutMain,
    element: DocumentComposerPage,
  },
  {
    path: '/admin/compositor-presupuestos/:documentId',
    layout: LayoutMain,
    element: CompositorPresupuestos,
  },
  {
    path: '/admin/mantenciones/calendario',
    layout: LayoutMain,
    element: MantencionesCalendario,
  },
  {
    path: '/admin/mantenciones/:id',
    layout: LayoutMain,
    element: Mantencion,
  },
  {
    path: '/admin/maintance/asignations',
    layout: LayoutMain,
    element: Asignations,
  },
  {
    path: '/admin/mantenciones/actividades',
    layout: LayoutMain,
    element: Activities,
  },
  {
    path: '/admin/maintance/categories',
    layout: LayoutMain,
    element: Category,
  },
  {
    path: '/admin/maintanceReports',
    layout: LayoutMain,
    element: MaintanceReportsAdmin,
  },
  {
    path: '/admin',
    layout: LayoutMain,
    element: Home,
  },
  {
    path: '/',
    layout: LayoutMain,
    element: Home,
  },
  {
    path: '/admin/budget',
    layout: LayoutMain,
    element: BudgetPageContainer,
  },
  {
    path: '/admin/budget/:id/composer/:documentId',
    layout: LayoutMain,
    element: PresupuestosPage,
  },
  {
    path: '/admin/client',
    layout: LayoutMain,
    element: Client,
  },
  {
    path: '/admin/client/:id/datos',
    layout: LayoutMain,
    element: DatosCliente,
  },
  {
    path: '/admin/clientes/:id',
    layout: LayoutMain,
    element: IDClientePage,
  },
  {
    path: '/admin/folders',
    layout: LayoutMain,
    element: Folders,
  },
  {
    path: '/admin/budget/add-form',
    layout: LayoutMain,
    element: AddBudgetForm,
  },
  {
    path: '/admin/certificacion',
    layout: LayoutMain,
    element: Certificacion,
  },
  {
    path: '/admin/certificacion/nueva',
    layout: LayoutMain,
    element: AddCertification,
  },
  {
    path: '/admin/certificacion/:id',
    layout: LayoutMain,
    element: CertificationDetail,
  },
  {
    path: '/*',
    layout: LayoutMain,
    element: Error404,
  },
];
const routes = [...routesAdmin, ...routesSpecialist, ...routesMain];

export default routes;
